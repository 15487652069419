import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
  MutationAction,
} from "vuex-module-decorators";
import { VehicleSearch, TravelCompany } from "@/store/models/travelCompany";
import TravelCompanyAPI from "@/store/api/travelCompany";
import store from "@/store";

@Module({
  namespaced: true,
  name: "checkboxTravelCompany",
  store,
  dynamic: true,
})
class CheckBoxTravelCompany extends VuexModule {
  TravelCompanyList: TravelCompany[] = [];

  @MutationAction
  async getTravelCompanyList(vehicleSearch: VehicleSearch) {
    let query = "?";
    if (vehicleSearch.source) {
      query += `&source=${vehicleSearch.source}`;
    }
    if (vehicleSearch.destination) {
      query += `&destination=${vehicleSearch.destination}`;
    }

    const response = await TravelCompanyAPI.getTravelCompanyList(query);
    return { TravelCompanyList: response };
  }
}
export default getModule(CheckBoxTravelCompany);
