import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";


export default new class TravelCompanyAPI {
    public async getTravelCompanyList(query = ""): Promise<any> {
        const action = `${Action.planTravelChoices}${query}`;
        const response = await getApi(action);
        return response as any;
    }
}