

























import "ol/ol.css";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import Feature from "ol/Feature";

import Observable from "ol/Observable";
import Point from "ol/geom/Point";
import Vector from "ol/source/Vector";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON.js";
import View from "ol/View";
import Map from "ol/Map";
import Tile from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import OSM from "ol/source/OSM";
import BingMaps from "ol/source/BingMaps";
import Projection from "ol/proj/Projection";
import Cluster from "ol/source/Cluster";

import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import VectorLayer from "ol/layer/Vector";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Icon from "ol/style/Icon";
import WKT from "ol/format/WKT";
import * as proj from "ol/proj";

import * as easing from "ol/easing";

import ZoomToExtent from "ol/control/ZoomToExtent";
import ScaleLine from "ol/control/ScaleLine";
import control from "ol/control";
import Control from "ol/control/Control";
import MousePosition from "ol/control/MousePosition";
import coordinate from "ol/coordinate";
import Polygon from "ol/geom/Polygon";

import { eventHub } from "@/utils/eventHub";

import { Checkbox } from "element-ui";

import {
  CommonStore,
  IncidentModule,
  MetaModule,
  NotificationModule,
  RouteModule,
} from "@/store/modules";

import Meta from "@/store/modules/meta";
import { Incident, Patrolling } from "@/store/models/incident";
import { Route } from "@/store/models/route";
import { FeatureIcon, FeatureType, IncidentStatus } from "@/utils/constants";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
} from "@/store/modules";

import popup from "@/views/incident/subComponents/popup.vue";
import stationpopup from "@/views/incident/subComponents/stationPopup.vue";
import patrolloverlay from "@/views/incident/subComponents/map/patrollOverlay.vue";
import panicmodeoverlay from "@/views/incident/subComponents/map/panicModeOverlay.vue";
import incidentoverlay from "@/views/incident/subComponents/map/incidentOverlay.vue";
import routeoverlay from "@/views/incident/subComponents/map/routeOverlay.vue";
import incident from "@/store/api/incident";
import helper from "@/utils/helpers";
import helpers from "@/utils/helpers";
import Gallery from "@/components/UIComponents/gallery.vue";
import Rightdash from "@/views/incident/rightdash.vue";
import { StopData, VehicleList } from "@/store/models/meta";
import Index from "@/views/admin/index.vue";
import { AdminRouter } from "@/utils/routePathContsant";
import SocketHandler from "@/utils/socketHandler";
import IconAnchorUnits from "ol/style/IconAnchorUnits";

// import incident from "../../store/api/incident";
var container = document.getElementById("ruleViolationpopup") as any;
var content = document.getElementById("popup-content") as any;
var closer = document.getElementById("popup-closer") as any;

type GeometryType =
  | "Point"
  | "LineString"
  | "LinearRing"
  | "Polygon"
  | "MultiPoint"
  | "MultiLineString"
  | "MultiPolygon"
  | "GeometryCollection"
  | "Circle";

@Component({
  components: {
    Checkbox,
    popup,
    stationpopup,
    patrolloverlay,
    incidentoverlay,
    panicmodeoverlay,
    routeoverlay,
    Gallery,
    Rightdash,
  },
})
export default class MapComponent extends Vue {
  //#region prop
  name = "Initial";
  public isActive: boolean = false;
  public isIncidentLoading = false;
  public category: string = "";
  public district: number = 0;
  public incidents: Incident[] = [];
  public stopData: StopData[] = [];
  public selectedIncidentKey: string = "";
  public checkList: boolean = false;
  public selectedStationInfo: any = {};
  public dialog = false;

  public historyDialog = false;
  public historyRoute: string = "";
  public historyVehicle: string = "";

  public searchVehicle: string = "";
  public showVehicleSearchBar: boolean = false;
  public options: any[] = [] as any;

  public selectedMaterialType = "";
  $refs!: {
    mapTarget: InstanceType<typeof HTMLElement>;
    tooltip: InstanceType<typeof HTMLElement>;
    zoomToRoute: InstanceType<typeof HTMLElement>;
    vehicleSearchButton: InstanceType<typeof HTMLElement>;
    vehicleSearchBar: InstanceType<typeof HTMLElement>;
    ruleViolationPopup: InstanceType<typeof HTMLElement>;
    ruleViolationPopupCloser: InstanceType<typeof HTMLElement>;
    ruleViolationPopupContent: InstanceType<typeof HTMLElement>;
  };
  @Watch("selectedIncidentKey")
  onSelectedIncidentKeyChanged(oldVal: any, newVal: any) {
    IncidentModule.setSelectedIncidenId(this.selectedIncidentKey);
  }

  get routes() {
    return MetaModule.routesListOfUser;
  }

  @Watch("traccarFeatures")
  onTraccerFeatureUpdate(newFeatures: any) {
    this.pointVectorSource.clear();
    this.pointVectorSource.addFeatures(newFeatures);
    //this.findVehicle("");
  }

  // get routeGeoJSON() {
  //   return Meta.routeGeoObj;
  // }

  // get stopGeoJSON() {
  //   return Meta.stopGeoObj;
  // }

  @Prop()
  routeGeoJSON: any;

  @Prop()
  stopGeoJSON: any;

  @Watch("routeGeoJSON", { immediate: true, deep: true })
  onRouteChange(newFeatures: any) {
    if (this.routeVectorSource) {
      this.routeVectorSource.clear();
    }
    this.renderRoute();
  }

  @Watch("stopGeoJSON", { immediate: true, deep: true })
  onStopChange(newFeatures: any) {
    if (this.stopVectorSource) {
      this.stopVectorSource.clear();
    }
    this.renderRoute();
  }

  get traccarFeatures() {
    return CommonStore.vehicleTrackFeatures;
  }

  get liveMonitoringData() {
    return CommonStore.liveMonitoringData;
  }

  close() {
    this.ruleViolationPopup.setPosition(undefined);
    this.$refs.ruleViolationPopupCloser.blur();
    return false;
  }

  //#endregion

  //#region Map
  map = new Map({});
  osmMap: string = "OSM";
  hide = true;
  popup = new Overlay({});
  ruleViolationPopup = new Overlay({
    element: document.getElementById("ruleViolationpopup") as HTMLElement,
  });
  tileLayerList: Tile[] = [];
  projection = new Projection({
    code: "EPSG:4326",
    units: "degrees",
    axisOrientation: "neu",
  });

  bounds: [number, number, number, number] = [
    80.0585566380344,
    26.3478190101467,
    88.2014946472075,
    30.4471622139934,
  ];

  zoomToExtentControl = new ZoomToExtent({
    extent: this.bounds,
  });

  routeExtent: [number, number, number, number] = [
    80.0585566380344,
    26.3478190101467,
    88.2014946472075,
    30.4471622139934,
  ];

  zoomToRoute = new ZoomToExtent({
    label: "R",
    tipLabel: "Zoom to route",
    extent: this.routeExtent,
  });

  mousePositionControl = new MousePosition({
    // coordinateFormat: coordinate.createStringXY(),
    projection: "EPSG:4326",
    undefinedHTML: "",
  });

  routeVectorSource: Vector = new Vector();
  routeVectorLayer: VectorLayer = new VectorLayer();
  pointVectorSource: Vector = new Vector();
  pointVectorLayer: VectorLayer = new VectorLayer();
  routeVectorClusterSource: Cluster = new Cluster({
    source: this.routeVectorSource,
  });

  stopVectorSource: Vector = new Vector();
  stopVectorLayer: VectorLayer = new VectorLayer();

  ruleViolationPointVectorSource: Vector = new Vector();
  ruleViolationPointVectorLayer: VectorLayer = new VectorLayer();

  selectedRouteFeature: Feature = new Feature();
  cord: any;
  trackVehicle = {
    vehicleNum: "",
    id: "",
  };
  socket: WebSocket | undefined = undefined;

  mapUpdate = () => {};

  async created() {
    var that = this;
    this.setLayers();
    const id = that.$route.query["vn"];
    await metaStore.getVehicleNumberById(id);
    this.trackVehicle.vehicleNum = metaStore.vehicleNumber;
    // if (id) {
    //   metaStore.getVehicleNumberById(id);
    //   this.trackVehicle.vehicleNum = metaStore.vehicleNumber;
    // } else {
    //   metaStore.loadVehicleListDD();
    // }
    this.updateSocket(id);
    eventHub.$on("mouse-over", this.mouseover);
    eventHub.$on("mouse-out", this.mouseout);
    eventHub.$on("error", this.handleError);
    eventHub.$on("map-size-updated", this.onUpdateMapSize);
  }

  async updateSocket(id: any) {
    if (this.socket) {
      this.map.getView().fit(this.bounds);
      this.socket.close();
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    this.socket = SocketHandler.createConnection("vehicle/" + id + "/");
  }

  onUpdateMapSize(e: any) {
    this.map.updateSize();
  }

  handleError(e: any) {
    commonstore.hideLoading();
  }

  makeText = (text: any) => {
    return new Text({
      fill: new Fill({ color: "black" }),
      stroke: new Stroke({ color: "white", width: 2 }),
      text: text,
      textBaseline: "top",
      font: "bold 15px serif",
      offsetY: -40,
    });
  };

  async mounted() {
    this.setMap();
  }

  async renderRoute() {
    var geojsonObject = this.routeGeoJSON;
    var stopjsonObject = this.stopGeoJSON;

    if (geojsonObject) {
      this.routeVectorSource = new Vector({
        features: new GeoJSON().readFeatures(geojsonObject),
      });

      // Remove earlier layer of route before adding new layer
      if (this.routeVectorLayer) {
        this.map.removeLayer(this.routeVectorLayer);
      }

      this.routeVectorLayer = new VectorLayer({
        source: this.routeVectorSource,
        style: (feature) => {
          const color =
            feature === this.selectedRouteFeature ? "green" : "coral";

          return new Style({
            stroke: new Stroke({
              color: color,
              width: 5,
            }),
          });
        },
      });
      const extent = this.routeVectorSource.getExtent();
      this.routeExtent = extent;
      this.zoomToRoute = new ZoomToExtent({
        className: "zoom-to-route",
        label: "R",
        tipLabel: "Zoom to route",
        extent: this.routeExtent,
      });

      this.map.getView().fit(extent);
      this.map.addLayer(this.routeVectorLayer);
    }

    const stopPng = require("@/assets/icons/stop1.png");
    const busPng = require("@/assets/icons/bus.png");
    const arrowPng = require("@/assets/icons/arrow.svg");

    if (stopjsonObject) {
      this.stopVectorSource = new Vector({
        features: new GeoJSON().readFeatures(stopjsonObject),
      });

      // Remove earlier layer of route before adding new layer
      if (this.stopVectorLayer) {
        this.map.removeLayer(this.stopVectorLayer);
      }
      this.stopVectorLayer = new VectorLayer({
        source: this.stopVectorSource,
        style: new Style({
          image: new Icon({
            color: "rgba(255, 0, 0, .5)",
            crossOrigin: "anonymous",
            src: stopPng,
            scale: 0.04,
            anchor: [0.5, 0.9],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
          }),
        }),
      });
      this.stopVectorLayer.setZIndex(1);
      this.map.addLayer(this.stopVectorLayer);
    }

    const busStyle = (feature: any) => {
      const rotationInDegree = feature.get("course");
      const rotation = rotationInDegree
        ? (rotationInDegree / 180) * Math.PI
        : 0;

      const bus = new Style({
        image: new Icon({
          color: "rgba(255, 0, 0, .5)",
          crossOrigin: "anonymous",
          src: busPng,
          // rotation,
          scale: 0.15,
        }),
        text: new Text({
          text: `${feature.get("vehicleNumber")} \n ${Number(
            feature.get("speed")
          ).toFixed(2)}kmph`,

          offsetY: -50,
          font: "Bold 10px Arial",
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 3 }),
        }),
      });
      this.cord = feature.getGeometry().flatCoordinates;
      const center = (proj as any).fromLonLat(
        [this.cord[0], this.cord[1]],
        this.projection
      );
      this.map.getView().animate({
        center: center,
        duration: 2000,
        zoom: 15,
      });
      const arrow = new Style({
        image: new Icon({
          // color: 'rgba(255, 0, 0, .5)',
          crossOrigin: "anonymous",
          src: arrowPng,
          scale: 0.8,
          rotation,
        }),
      });
      return [arrow, bus];
    };

    this.pointVectorLayer.setStyle(busStyle);
    this.pointVectorLayer.setSource(this.pointVectorSource);
    this.pointVectorLayer.setZIndex(1);

    this.map.addLayer(this.pointVectorLayer);
    var map = this.map;
  }

  filterVehicle() {
    var filterData = metaStore.vehicleList;
    if (this.trackVehicle.vehicleNum !== "") {
      commonstore.clearTraccar();
      this.updateSocket(this.trackVehicle.vehicleNum);
    }
    this.options = filterData;
  }
  hideHoverInfo() {
    const tooltip: any = this.$refs.tooltip;
    tooltip.style.opacity = 0;
  }

  loadIcon(type: string) {
    const path = require("@/assets/" + type + ".svg");
    const url = "url(" + path + ") no-repeat center center";
    return url;
  }

  mouseover(e: any) {
    const el = document.getElementById("incident_" + e) as any;
    if (el) {
      el.classList.add("markerHovered");
    }
  }

  mouseout(e: any) {
    const el = document.getElementById("incident_" + e) as any;
    if (el) {
      el.classList.remove("markerHovered");
    }
  }

  onMaterialSectionClicked(e: any) {
    this.selectedMaterialType = e;
    this.dialog = true;
  }

  getFeatureStyle(icon: string) {
    const style = new Style({
      image: new Icon({
        rotateWithView: true,
        // rotation: this.get('angle') * Math.PI / 180,
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
      }),
    });
    return style;
  }

  closePopup(e: any) {
    this.popup.setPosition(undefined);
  }

  getLayer(layerID: string) {
    const layers = this.map.getLayers().getArray();
    let l = layers[0] as Tile;
    for (const layer of layers) {
      if (layer.get("id") === layerID) {
        l = layer as Tile;
        return l;
      }
    }
    return null;
  }

  setLayers() {
    let osm = new Tile({
      visible: true,
      source: new OSM(),
    });
    osm.set("id", "OSM");
    this.tileLayerList.push(osm);
    let bing = new Tile({
      visible: false,
      source: new BingMaps({
        key: "ApTJzdkyN1DdFKkRAE6QIDtzihNaf6IWJsT-nQ_2eMoO4PN__0Tzhl2-WgJtXFSp",
        imagerySet: "AerialWithLabels",
      }),
    });
    bing.set("id", "BingMap");
    this.tileLayerList.push(bing);
  }

  scaleControl() {
    let ctrl = new ScaleLine({
      units: "metric",
    });

    return ctrl;
  }

  createStringXY(fraction = 4): any {
    return (coordinate: any) => {
      if (coordinate) {
        let template = "{x}, {y}";
        return template
          .replace("{x}", coordinate[0].toFixed(fraction))
          .replace("{y}", coordinate[1].toFixed(fraction));
      }
    };
  }

  setMap() {
    this.map = new Map({
      view: new View({
        center: [83.6596, 28.4011],
        //   center: [0, 0],
        zoom: 6,
        maxZoom: 19,
        projection: this.projection,
      }),
      target: this.$refs.mapTarget,
    });

    this.map.getView().fit(this.bounds);
    // this.renderRoute();
    this.map.addControl(this.zoomToExtentControl);
    this.map.addControl(
      new ScaleLine({
        units: "metric",
      })
    );
    this.map.addControl(
      new MousePosition({
        coordinateFormat: this.createStringXY(),
        projection: "EPSG:4326",
      })
    );
    this.ruleViolationPopup = new Overlay({
      element: this.$refs.ruleViolationPopup,
    });
    this.map.addOverlay(this.popup);
    this.map.addOverlay(this.ruleViolationPopup);
    this.popup.setPosition(undefined);

    var el = document.getElementById("popup") as HTMLElement;
    this.popup.setElement(el);

    this.map.on("click", async (evt) => {
      var element = this.popup.getElement();
      const e = evt as any;
      const coordinate = [e.coordinate[0], e.coordinate[1]] as [number, number];

      var feature = this.map.forEachFeatureAtPixel(e.pixel, function(feature) {
        return feature;
      });
    });

    for (const layer of this.tileLayerList) {
      this.map.addLayer(layer);
    }

    //set vector layer

    var that = this;

    (window as any).custom_map = this.map;
  }

  public switchMap(layer: string) {
    const osm = this.getLayer("OSM") as Tile;
    osm.setVisible(false);
    const bing = this.getLayer("BingMap") as Tile;
    bing.setVisible(false);
    if (layer != "None") {
      const mapLayer = this.getLayer(layer) as Tile;
      if (mapLayer != null) {
        mapLayer.setVisible(true);
      }
    }
  }

  updateVisibility(layerId: string, visible: boolean) {
    for (let i = 0; i < this.tileLayerList.length; i++) {
      if (layerId == this.tileLayerList[i].getProperties().id) {
        this.tileLayerList[i].setVisible(visible);
        break;
      }
    }
  }

  toggleHide() {
    this.hide = !this.hide;
    this.map.updateSize();
  }

  resizeMap() {
    this.map.updateSize();
  }

  findVehicle(query: any) {
    if (query !== "") {
      const vehicles = metaStore.vehicleList;
      // setTimeout(() => {
      this.options = vehicles.filter((vehicle) => {
        return (
          vehicle.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
      // }, 200);
    } else {
      this.options = [];
    }
  }
  toggleVehicleSearchBar() {
    this.showVehicleSearchBar = !this.showVehicleSearchBar;
    this.searchVehicle = "";
  }

  beforeDestroy() {
    this.map.dispose();
  }
}
