















































































































































































































































import { Vue, Component } from "vue-property-decorator";
import RouteMap from "@/components/NewUIComponents/routeMap.vue";
import { MetaModule, VehicleModule } from "@/store/modules";
import { VehicleSearch } from "@/store/models/registration/registration";
import { SourceDestination } from "@/store/models/vechileSchedule";
import checkboxTravelCompany from "@/store/modules/travelCompany";
import helper from "@/utils/helpers";

@Component({
  components: {
    RouteMap,
  },
})
export default class PlanTravel extends Vue {
  currentSource: string = "";
  currentDestination: string = "";
  checkList = [];
  sortBy: string = "";
  loading: boolean = false;
  options: SourceDestination[] = [] as any;
  destinationOptions: SourceDestination[] = [] as any;

  get searchBus() {
    return VehicleModule.vehicleSearch;
  }

  set searchBus(vehicleSearch: VehicleSearch) {
    VehicleModule.setVehicleSearch(vehicleSearch);
  }

  get vehicleSearchResult() {
    return VehicleModule.vehicleSearchResult;
  }

  get travelCompanyList() {
    return checkboxTravelCompany.TravelCompanyList;
  }

  handleOptionChange(e: string) {
    this.searchBus.ordering = this.sortBy;
    this.searchBusBtn();
  }

  handleTcChange(e: any) {
    this.searchBus.vehicle_owners = e;
    this.searchBusBtn();
  }

  async handlePageChange(val: number) {
    this.loading = true;
    await this.searchBusBtn(val);
    this.loading = false;
  }

  momentTime(time: any) {
    return helper.toMomentTime(time);
  }

  async searchBusBtn(page: number = 1) {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      this.searchBus.page = page;
      await VehicleModule.searchPlanYourTravel(this.searchBus);
      await checkboxTravelCompany.getTravelCompanyList(this.searchBus);
      MetaModule.loadRouteGeoObj(this.searchBus);
      MetaModule.loadStopGeoObj(this.searchBus);
      this.$router
        .replace({
          name: "PlanTravel",
          query: {
            source: this.searchBus.source,
            destination: this.searchBus.destination,
            shift: this.searchBus.shift,
          },
        })
        .catch((e) => {
          e;
        });
      this.currentSource = this.$route.query.source as string;
      this.currentDestination = this.$route.query.destination as string;
    }
  }

  async created() {
    if (this.$route.query.source) {
      this.$set(this.searchBus, "source", this.$route.query.source as string);
      this.currentSource = this.$route.query.source as string;
    }
    if (this.$route.query.destination) {
      this.$set(this.searchBus, "destination", this.$route.query
        .destination as string);
      this.currentDestination = this.$route.query.destination as string;
    }
    if (this.$route.query.shift) {
      this.$set(this.searchBus, "shift", this.$route.query.shift as string);
    }

    if (this.currentSource != "" && this.currentDestination != "") {
      this.searchBusBtn();
    }
    await MetaModule.loadSourceDestinationDD();
    this.options = MetaModule.sourceDestinationList;
  }

  async onDepartureSelect() {
    await MetaModule.loadDestinationOnBasisOfSource(this.searchBus.source);
    this.destinationOptions = MetaModule.destinationOnBasisOfSourceList;
  }
}
