

































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VehicleSearchMap from "@/views/public/vehicleSearchMap.vue";
import { MetaModule } from "@/store/modules";

import api from "@/store/api/meta";

@Component({ components: { VehicleSearchMap } })
export default class RouteMap extends Vue {
  shift = "";
  sourceName = "";
  destinationName = "";

  @Prop({ required: true })
  source!: string;

  @Prop({ required: true })
  destination!: string;

  @Watch("source")
  onSourceChange(val: string, oldVal: string) {
    if (val != oldVal) {
      this.source = val;
    }
    this.filterStops();
  }

  @Watch("destination")
  onDestinationChange(val: string, oldVal: string) {
    if (val != oldVal) {
      this.destination = val;
    }
    this.filterStops();
  }

  stopListName: any = [];

  async created() {
    this.shift = this.$route.query.shift.toString();
    this.stopListName = [];
    this.filterStops();
  }

  get routeGeoJSON() {
    return MetaModule.routeGeoObj;
  }

  get stopGeoJSON() {
    return MetaModule.stopGeoObj;
  }

  async filterStops() {
    let params = "?";
    params += `source=${this.source}&destination=${this.destination}`;
    let stopList = await api.getStopData(params);
    // if (this.destination !== stopList[0]["name"]) {
    //   stopList = stopList.reverse();
    // }
    this.sourceName = stopList[0].name;
    this.destinationName = stopList[stopList.length - 1].name;

    // Worked on showing lesser stops in map
    const stopLength = stopList.length;
    let left = [];
    let right = [];

    // Find mid index and stop in that index
    const midNum = Math.round(stopLength / 2);
    const midStop = stopList[midNum];

    // Remove mid stop from list as we already have it
    // in "midStop" variable just above
    stopList.splice(midNum, 1);

    // Get stops at right and left side of mid index and only get those
    // whose gid are divisible by 2.
    // (Only did this filter to reduce stops)

    left = stopList.slice(1, midNum).filter((e: any) => e.gid % 2 === 0);
    right = stopList
      .slice(midNum, stopLength)
      .filter((e: any) => e.gid % 2 === 0);

    // Now push(add) all these to new array and use it in template.
    // Clear list before pushing.
    this.clearStopListName();
    this.stopListName.push(...left, midStop, ...right);
  }

  clearStopListName() {
    this.stopListName.length = 0; // Clearing(empty) Array
  }
}
